<template>
  <WidgetFrame  @onResize="onResize" @onOrientationChange="onOrientationChange" v-bind:class="sizeClass">>
    <template v-slot:title>
      Progress
    </template>
    <template v-slot:content>
      <template v-if="!loaded">
        <div class='status-message'>Loading...</div>
      </template>
      <template v-else>
        <template v-if="uuId">
          <div class="project-link">
            <a v-on:click="$router.push('/projects/summary/'+uuId)" href="#">
              <v-clamp autoresize :max-lines="1">
                {{ pProject.name }}
              </v-clamp>
            </a>
          </div>
        </template>
        <div class="summary-text">  
          <template v-if="notStarted">
            <i class="far fa-minus-square" :style="{ color: 'var(--status-orange)' }"></i>
            <v-clamp autoresize :max-lines="1">Not Started</v-clamp>
          </template>
          <template v-else-if="good">
            <i class="far fa-circle-check" :style="{ color: 'var(--status-green)' }"></i>
            <v-clamp autoresize :max-lines="1">On Schedule</v-clamp>
          </template>
          <template v-else-if="behind">
            <i class="fas fa-triangle-exclamation" :style="{ color: 'var(--status-red)' }"></i>
            <v-clamp autoresize :max-lines="1">Behind Schedule</v-clamp>
          </template>
          <template v-else-if="ahead">
            <i class="far fa-circle-check" :style="{ color: 'var(--status-green)' }"></i>
            <v-clamp autoresize :max-lines="1">Ahead of Schedule</v-clamp>
          </template>
          <template v-else-if="complete">
            <i class="far fa-circle-check" :style="{ color: 'var(--status-green)' }"></i>
            <v-clamp autoresize :max-lines="1">Completed</v-clamp>
          </template>
        </div>        
        <div class='progress-chart-container'>
          <div ref="actualcontainer" style="position: relative">
            <canvas ref="chartActual"></canvas>
          </div>
          <div ref="plannedcontainer" style="position: relative">
            <canvas ref="chartPlanned"></canvas>
          </div>
        </div>
      </template>
    </template>
  </WidgetFrame>
</template>
<script>
import Vue from 'vue';
import Chart from "chart.js";
import WidgetFrame from "@/components/Dashboard/WidgetFrame";
import VClamp from 'vue-clamp'
import cColors from "@/_chartColors";
import { EventBus } from '@/helpers';

const CenterLabel = {
  beforeDraw: function (chart) {
    var width = chart.width,
        height = chart.height;
    let smallest = Math.min(width, height);
    chart.ctx.font = 'bold ' + (smallest / 80).toFixed(2) + "em 'Roboto'";
    chart.ctx.textBaseline = "middle";
    
    var config = chart.config.options.elements.centerText;
    var percent = config.percent ? config.percent : 0;
    var text = percent,
        textX = Math.round((width - chart.ctx.measureText(text).width) / 2),
        textY = (height / 2) + 16;
    chart.ctx.fillStyle = config.color;
    chart.ctx.fillText(text, textX, textY);
  }
};

export default {
  name: 'ProjectProgressWidget',
  components: {
    WidgetFrame,
    VClamp
  },
  props: {
    project: { type: Object },
    uuId:    { type: String },
    projects: { type: Object }
  },
  watch: {
    projects: function () {
      if (!this.loaded) {
        var p = this.projects[this.uuId];
        if (p) {
          this.pProject = p;
          this.loaded = true;
          var self = this;
          Vue.nextTick(function() {
            self.buildChart();
          });
        }
      }
    },
  },
  computed: {
    sizeClass: function () {
      let size = {
        'home': this.uuId != null
      };
      if (this.height < 340) {
        size['SMH'] = true;
      }
      if (this.width < 240) {
        size['SMW'] = true;
      }
      if (this.width < 180) {
        size['VSMW'] = true;
      }

      return size;
    },
  },
  data() {
    return {
      orientation: null,
      width: 0,
      height: 0,
      loaded: false,
      pProject: null, // to avoid mutating prop
      // Progress state, only one can be true
      good: false,
      behind: false,
      ahead: false,
      notStarted: false,
      complete: false,
    };
  },
  created() {
    EventBus.$on('theme-change', () => {
      if (this.loaded) {
        this.buildChart();
      }
    });

    if (this.uuId) {
      // We were given an ID instead of a project. We must wait for the projects
      // map to contain our project before we proceed (via watch).
      // Might already have it (e.g., toggle widget from settings after load)
      var p = this.projects[this.uuId];
      if (p) {
        this.pProject = p;
        this.loaded = true;
      }
    } else {
      this.pProject = this.project;
      this.loaded = true;
    }
  },
  mounted() {
    if (this.loaded) {
      this.buildChart();
    }
  },
  beforeDestroy() {
    EventBus.$off('theme-change');
  },
  methods: {
    buildChart() {
      Chart.defaults.global.defaultFontFamily = 'Roboto';
      var actual = this.pProject.progress ? (this.pProject.progress * 100).toFixed(0) : 0;
      var planned = typeof this.pProject.plannedProgress !== 'undefined' ? (this.pProject.plannedProgress * 100).toFixed(0) : 0;

      // Actual
      const diff = actual - planned;
      var color;
      if (actual == 0 && planned == 0) {
        this.notStarted = true;
        color = cColors.getThemeColor('status-none');
      } else if (planned == 100 && actual < 100) {
        // Special case: if planned is 100, anything less is behind
        this.behind = true;
        color = cColors.getThemeColor('status-red');
      } else if (actual == 100) {
        this.complete = true;
        color = cColors.getThemeColor('status-green');
      } else if (diff >= -10 && diff <= 10) {
        // 10% tolerance is 'good'
        this.good = true;
        color = cColors.getThemeColor('status-green');
      } else if (diff > 10) {
        // More than 10% ahead is 'ahead'
        this.ahead = true;
        color = cColors.getThemeColor('status-green');
      } else {
        // Less than 10% is 'behind'
        this.behind = true;
        color = cColors.getThemeColor('status-red');
      }

      let ctx = this.$refs.chartActual.getContext("2d");
      this.chart = new Chart(ctx, {
        type: "doughnut",
        data: {
          datasets: [{
            data: [actual, 100-actual],
            backgroundColor: [color],
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          cutoutPercentage: 60,
          title: {
            display: true,
            text: 'Actual',
            fontSize: this.width < 240 ? "12" : "14",
            fontColor: cColors.getThemeColor('text-medium'),
            padding: 0,
          },
          tooltips: {enabled: false},
          hover: {mode: null},
          elements: {
            centerText: {
              percent: actual + "%",
              color: color,
            },
            arc: {
              borderWidth: 4,
              borderColor: cColors.getThemeColor('stroke')
            }
          },
        },
        plugins: [CenterLabel]
      });

      // Planned
      color = planned == 0 ? cColors.getThemeColor('status-none') : cColors.getThemeColor('status-orange');
      ctx = this.$refs.chartPlanned.getContext("2d");
      this.chart = new Chart(ctx, {
        type: "doughnut",
        data: {
          datasets: [{
            data: [planned, 100-planned],
            backgroundColor: [color],
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          cutoutPercentage: 60,
          title: {
            display: true,
            text: 'Planned',
            fontSize: this.width < 240 ? "12" : "14",
            fontColor: cColors.getThemeColor('text-medium'),
            padding: 0,
          },
          tooltips: {enabled: false},
          hover: {mode: null},
          elements: {
            centerText: {
              percent: planned + "%",
              color: color,
            },
            arc: {
              borderWidth: 4,
              borderColor: cColors.getThemeColor('stroke')
            }
          },
        },
        plugins: [CenterLabel]
      });
      this.loaded = true;
    },
    onOrientationChange({orientation, width, height}) {
      this.orientation = orientation;
      this.onResize({width, height});
    },
    onResize({width, height}) {
      this.width = width;
      this.height = height;
      if (this.$refs.actualcontainer) {
        this.$refs.actualcontainer.style.height = `${height - 155}px`;
      }
      if (this.$refs.plannedcontainer) {
        this.$refs.plannedcontainer.style.height = `${height - 155}px`;
      }
    }
  }
}
</script>


<style lang="scss" scoped>
  .project-link {
    text-align: center;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .project-link:hover {
    text-decoration: underline;
  }

  .progress-chart-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex: 1;
    padding-top: 5px;
    padding-bottom: 15px;
  }

 .progress-chart-container > div {
   width: 45%;
   display: flex;
   justify-content: space-around;
   outline: 1px solid var(--border-medium);
   border-radius: 4px;
   padding-top: 14px;
   padding-bottom: 12px;
   margin-bottom: 12px;
 }

 /* Responsive styles */

 .SMW .summary-text {
   font-size: 14px;
 }
 .SMW .summary-text svg {
   font-size: 18px;
   margin-right: 6px;
 }
 .VSMW .summary-text {
   font-size: 12px;
 }
 .VSMW .summary-text svg {
   font-size: 16px;
   margin-right: 4px;
 }
 .VSMW .progress-chart-container > div {
   width: 50%;
   outline: none;
 }
 .VSMW .progress-chart-container > div:nth-child(1) {
   border-top: 1px solid var(--border-medium);
   border-left: 1px solid var(--border-medium);
   border-bottom: 1px solid var(--border-medium);
   border-top-right-radius: 0;
   border-bottom-right-radius: 0;
 }
 .VSMW .progress-chart-container > div:nth-child(2) {
   border-top: 1px solid var(--border-medium);
   border-right: 1px solid var(--border-medium);
   border-bottom: 1px solid var(--border-medium);
   border-top-left-radius: 0;
   border-bottom-left-radius: 0;
 }

 /*
  For some reason, the chart height exceeds its container at smaller sizes, so
  this is a workaround to explicitly set the height. Home dashboards have the
  project name consuming a line so they need to be even smaller.
  */
 .SMH .progress-chart-container > div {
   height: 180px;
 }
 .SMH.home .progress-chart-container > div {
   height: 150px;
 }
</style>